<template>
<div>

  <div class="container-fluid wrapper-medium mt-5">
    <div class="row">
      <div class="col-12">
        <h1 class="anim-load1">
          TAYC-R application terms and conditions
        </h1>
        <span>as at 11/07/2022</span>
      </div>
    </div>
  </div>

  <div class="container-fluid wrapper-medium mt-5 mb-5">
    <div class="row">
      <div class="col-12 col-md-10">
        <p class="lead">Please read the following terms and conditions carefully as they govern the use of this application.</p>
        <p class="lead">Access to this application (taycr.adoptionplus.co.uk) is subject to the following conditions:</p>
        <ol>
            <li><strong>Trademarks</strong> - “TAYC-R”, “Adoptionplus” and the “Adoptionplus” logo are trademarks. All other trademarks and
logos used in this web-based app are the trademarks or logos of their respective owners.</li>
            <li><strong>Nature of the TAYC-R Application</strong> - TAYC-R is a web-based application, which can be accessed by navigating to the URL https://taycr.adoptionplus.co.uk on any internet capable device. As such it is not available to download from
any app marketplace like the Apple App Store or Google Play store. No account(s) with third
party platforms are required to access TAYC-R.</li>
            <li><strong>Content accuracy</strong> - Whilst all reasonable efforts have been made to ensure the accuracy of content, no responsibility
can be taken for any error or omission. No advice or information, whether oral or written, obtained
by you from the TAYC-R application shall create any warranty not expressly made in these terms
of service or imposed by law.</li>
          <li><strong>Registration</strong> - At the point of registration you will be invited by Adoptionplus to use TAYC-R. Adoptionplus’ data
processor, Greenwood Campbell is able to view all content uploaded. (see point 3 of <a href="/privacy-policy">privacy policy</a></li>
        </ol>
        <p>You agree that any information you input into TAYC-R upon registration is, to your best knowledge, true,
accurate, complete and updated.</p>
<h3>Password</h3>
<p class="lead">When you register to use the TAYC-R application you will be asked to create a password. You must keep
this password confidential and must not disclose it to or share it with anyone. You will be deemed
responsible for all activities that occur under your password. Please note you can change your password
at any time through your account settings within TAYC-R.</p>
      <ol>
          <li><strong>Your use of TAYC-R</strong> - You alone are responsible for the content inputted into TAYC-R and your use of the TAYC-R
application</li>
        </ol>
        <p>By using TAYC-R you agree to comply with the following guidelines:</p>
        <ol type="a">
          <li>Using the application for commercial purposes or to obtain financial gain is strictly prohibited
            without Adoptionplus’ prior written consent.</li>
        <li>You may not post to the application any message or material that is unlawful, harassing,
        defamatory, abusive, threatening, harmful, vulgar, obscene, profane, threatening, racially or
        sexually or otherwise discriminatory, offensive, inaccurate, in breach of confidence or a third
        party’s intellectual property rights or which otherwise includes objectionable material of any kind
        or nature, that encourages or engages in conduct that could constitute a criminal offence, give
        rise to civil liability or otherwise violate any applicable local, national or international law or
        regulation or which has a disruptive influence. You must not impersonate any other person or
        entity, whether actual or fictitious.</li>
          <li>You must not use TAYC-R in a way that might in any way damage the operation of another’s
              computer.</li>
        <li>We reserve the right, at our sole discretion, to terminate a user’s access to the application.</li>
        </ol>
        <ol>
          <li><strong>Intellectual Property</strong> (a) Adoptionplus is part of the Barnardo’s group. Adoptionplus owns the copyright of, and
other intellectual property rights, in TAYC-R and its contents. “Rights” means copyright, database
rights, trademarks, design rights and other intellectual and proprietary rights of whatever nature,
anywhere in the world. You acknowledge that (i) Adoptionplus and Barnardo’s are protected by
these Rights; (ii) these Rights are valid and protected in all media and technologies existing now
or later developed; and (iii) except as explicitly provided otherwise, these Terms and Conditions
and applicable copyright, trademark and other laws govern your use of such Rights.
(b) Users must not republish or reproduce any other part of TAYC-R either on another website, or
digitally as part of any commercial service without the prior written permission of Adoptionplus.</li>
<li><strong>Limitation of liability</strong> - Adoptionplus does not guarantee that access to this application will be uninterrupted, that this
web-based app will be free from viruses or that it cannot be tampered with by third parties. This
web-based app and the information are provided on an ‘as is’ basis, with no warranties of any
kind whatsoever, either express or implied, including, but not limited to, any warranties or any
implied warranties of merchantability or fitness for a particular purpose. Use of this web-based
app and the information is entirely at the user’s sole risk. In no event will Adoptionplus or
Barnardo’s be liable for any damages whatsoever arising out of or related to this web-based app.
Your sole and exclusive remedy for dissatisfaction with this web-based app and/or information is
to stop using the site and the information. By using TAYC-R you acknowledge that performance
and security are optimised by using up to date web browser and anti-virus software and it is the
user’s responsibility to have these in place. To optimise performance TAYC-R must be used on
Edge, Firefox and Chrome.</li>
<li><strong>Modification of the Terms and Conditions</strong> - Adoptionplus may modify the terms of this agreement on this site. You agree to periodically
review this agreement to be aware of any such revisions. Should the revision be unacceptable to
you, you agree to stop accessing this site. Your continued use of this web-based app following
such notification of any such revision(s) shall be deemed as acceptance of and agreement to
abide by all such revisions.</li>
<li>Adoptionplus is a company limited by guarantee (Registration number 06900397).</li>
          </ol>
          <p class="lead"><strong>The registered office is</strong>:<br/>Adoptionplus<br/>Barnardo's House<br/>Tanners Lane<br/>Barkingside,<br/>Ilford,<br/>Essex<br/>IG6 1QG</p>
      <p class="lead">Adoptionplus Ltd is part of the Barnardo’s group</p>
      </div>
    </div>
  </div>

  <Footer />

</div>
</template>

<script>

export default {
  name: 'TermsOfUse',
  data () {
    return {
    }
  },
  computed: {
  },
  created: function () {
    this.$store.state.loadingProgress = false
    this.$store.state.appSection = 'brochure'
  },
  beforeDestroy() {
  },
  methods: {
  }
}
</script>
